interface Wallet {
  id: number;
  address: string;
  totalValue: number;
}

interface Asset {
  name: string;
  symbol: string;
  amount: number;
  value: number;
  change: number;
  network: string;
  walletId: number;
  chartData: { time: string; price: number }[];
}

export enum Side {
  Buy = "Buy",
  Sell = "Sell"
}

interface Trade {
  id: number;
  walletId: number;
  pair: string;
  side: Side;
  amount: number;
  price: number;
  time: string;
  network: string;
  exchange: string;
}

interface NetworkOption {
  label: string;
  value: string;
}

interface ExchangeOption {
  label: string;
  value: string;
}

export type {
  Wallet,
  Asset,
  Trade,
  NetworkOption,
  ExchangeOption
}