import {
  WalletIcon,
  CoinsIcon,
  TrendingUpIcon,
  TrendingDownIcon,
  SearchIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  PackageIcon,
  LayersIcon,
  XIcon,
  CheckSquareIcon,
  SquareIcon,
  FilterIcon,
  XCircleIcon
} from "lucide-react";
import { ethers } from 'ethers';

import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import './index.css';
import './output.css';
import { Asset, ExchangeOption, NetworkOption, Side, Trade, Wallet } from "./types";
import Backend from "./components/backend";
import { ChevronRightIcon } from "lucide-react";
import { ChevronLeftIcon } from "lucide-react";
import { Loader2 } from "lucide-react";

const backend = new Backend();

const CryptoDashboard: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [expandedAsset, setExpandedAsset] = useState<string | null>(null);
  const [isBundled, setIsBundled] = useState<boolean>(true);
  const [selectedWallet, setSelectedWallet] = useState<number | null>(null);
  const [showWalletPopup, setShowWalletPopup] = useState<boolean>(false);
  const [showNetworkPopup, setShowNetworkPopup] = useState<boolean>(false);
  const [selectedNetworks, setSelectedNetworks] = useState<string[]>(["all"]);
  const [selectedExchanges, setSelectedExchanges] = useState<string[]>(["all"]);
  const [showExchangePopup, setShowExchangePopup] = useState<boolean>(false);
  const [wallets, setWallets] = useState<Wallet[]>(() => {
    const savedWallets = localStorage.getItem('wallets');
    return savedWallets ? JSON.parse(savedWallets) : [];
  });
  const [assets, setAssets] = useState<Asset[]>([]);
  const [trades, setTrades] = useState<Trade[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTradePage, setCurrentTradePage] = useState<number>(1);
  const assetsPerPage = 10;
  const tradesPerPage = 10;

  useEffect(() => {
    const loadSavedWalletData = async () => {
      setIsLoading(true);
      try {
        let allAssets: Asset[] = [];
        let allTrades: Trade[] = [];

        for (const wallet of wallets) {
          const walletAssets = await backend.fetchAssets(wallet.address);
          const walletTrades = await backend.fetchTrades(wallet.address);

          allAssets = [...allAssets, ...walletAssets.map(asset => ({ ...asset, walletId: wallet.id }))];
          allTrades = [...allTrades, ...walletTrades.map(trade => ({ ...trade, walletId: wallet.id }))];

          // Update wallet's total value
          const totalValue = walletAssets.reduce((sum, asset) => sum + asset.value, 0);
          wallet.totalValue = totalValue;
        }

        setAssets(allAssets);
        setTrades(allTrades);
        setWallets([...wallets]); // Trigger re-render with updated total values
      } catch (error) {
        console.error('Error loading saved wallet data:', error);
        // Optionally set an error state here to display to the user
      } finally {
        setIsLoading(false);
      }
    };

    if (wallets.length > 0) {
      loadSavedWalletData();
    }
  }, []);

  const handleWalletChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletAddress(e.target.value);
    setShowError(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidEthereumAddress(walletAddress)) {
      try {
        setIsLoading(true);
        const newWallet = await addWallet(walletAddress);
        setWallets(prevWallets => {
          const updatedWallets = [...prevWallets, newWallet];
          localStorage.setItem('wallets', JSON.stringify(updatedWallets));
          return updatedWallets;
        });

        setWalletAddress("");
        setShowError(false);
      } catch (error) {
        console.log(error);
        setShowError(true);
        // setErrorMessage("Error adding wallet. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log('Invalid ethereum address provided');
      setShowError(true);
      // setErrorMessage("Invalid Ethereum address");
    }
  };

  const addWallet = async (address: string): Promise<Wallet> => {
    const walletAssets = await backend.fetchAssets(address);
    console.log(walletAssets);
    const walletTrades = await backend.fetchTrades(address);
    console.log(walletTrades);
    const totalValue = walletAssets.reduce((sum, asset) => sum + asset.value, 0);

    const newWallet: Wallet = {
      id: wallets.length + 1,
      address,
      totalValue,
    };

    // Add new assets to the assets array
    const newAssets = walletAssets.map(asset => ({
      ...asset,
      walletId: newWallet.id,
    }));
    const newTrades = walletTrades.map(trades => ({
      ...trades,
      walletId: newWallet.id,
    }));


    setAssets(prevAssets => [...prevAssets, ...newAssets]);
    setTrades(prevTrades => [...prevTrades, ...newTrades]);

    return newWallet;
  };

  const isValidEthereumAddress = (address: string): boolean => {
    return ethers.isAddress(address);
  };


  const handleAssetClick = (symbol: string) => {
    setExpandedAsset(expandedAsset === symbol ? null : symbol);
  };

  const handleBundleToggle = () => {
    setIsBundled(!isBundled);
    setSelectedWallet(null);
  };

  const handleNetworkSelect = (network: string) => {
    setSelectedNetworks((prev) => {
      if (network === "all") {
        return ["all"];
      } else {
        const newNetworks = prev.filter((n) => n !== "all");
        if (newNetworks.includes(network)) {
          return newNetworks.filter((n) => n !== network);
        } else {
          return [...newNetworks, network];
        }
      }
    });
  };

  const handleExchangeSelect = (exchange: string) => {
    setSelectedExchanges((prev) => {
      if (exchange === "all") {
        return ["all"];
      } else {
        const newExchanges = prev.filter((e) => e !== "all");
        if (newExchanges.includes(exchange)) {
          return newExchanges.filter((e) => e !== exchange);
        } else {
          return [...newExchanges, exchange];
        }
      }
    });
  };

  const handleWalletSelect = (walletId: number) => {
    setSelectedWallet(walletId);
    setIsBundled(false);
    setShowWalletPopup(false);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextTradePage = () => {
    setCurrentTradePage((prev) => prev + 1);
  };

  const handlePrevTradePage = () => {
    setCurrentTradePage((prev) => Math.max(prev - 1, 1));
  };


  const removeWallet = (walletId: number) => {
    setWallets(prevWallets => {
      const updatedWallets = prevWallets.filter(wallet => wallet.id !== walletId);
      localStorage.setItem('wallets', JSON.stringify(updatedWallets));
      return updatedWallets;
    });
    setAssets(prevAssets => prevAssets.filter(asset => asset.walletId !== walletId));
    setTrades(prevTrades => prevTrades.filter(trades => trades.walletId !== walletId));
  };

  const shortenAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };
  const networkOptions: NetworkOption[] = [
    {
      label: "All Networks",
      value: "all",
    },
    {
      label: "Ethereum",
      value: "ethereum",
    },
    {
      label: "Arbitrum",
      value: "arbitrum",
    },
    {
      label: "Optimism",
      value: "optimism",
    },
    {
      label: "Base",
      value: "base",
    },
    {
      label: "zkSync",
      value: "zksync",
    },
  ];

  const exchangeOptions: ExchangeOption[] = [
    {
      label: "All Exchanges",
      value: "all",
    },
    {
      label: "UniswapV2",
      value: "UNI-V2",
    },
    {
      label: "UniswapV3",
      value: "UNI-V3",
    },
    {
      label: "SushiswapV2",
      value: "SUSHI-V2",
    },
    {
      label: "SushiswapV3",
      value: "SUSHI-V3",
    },
  ];

  const filteredAssets = assets.filter(
    (asset) =>
      (isBundled || asset.walletId === selectedWallet) &&
      (selectedNetworks.includes("all") ||
        selectedNetworks.includes(asset.network)),
  );
  const sortedAssets = filteredAssets.sort((a, b) => b.value - a.value);
  const paginatedAssets = sortedAssets.slice(
    (currentPage - 1) * assetsPerPage,
    currentPage * assetsPerPage
  );

  // Filter trades based on selected exchanges and wallet
  const filteredTrades = trades.filter(trade =>
    (selectedExchanges.includes("all") || selectedExchanges.includes(trade.exchange)) &&
    (isBundled || trade.walletId === selectedWallet)
  );
  const paginatedTrades = filteredTrades.slice(
    (currentTradePage - 1) * tradesPerPage,
    currentTradePage * tradesPerPage
  );


  const totalValue = filteredAssets.reduce(
    (sum, asset) => sum + asset.value,
    0,
  );

  interface LineChartProps {
    data: { time: string; price: number }[];
  }

  const LineChart: React.FC<LineChartProps> = ({ data }) => {
    const validData = data.filter(
      (d) => d.price !== undefined && d.price !== null,
    );
    if (validData.length === 0) {
      return (
        <div className="text-gray-400">
          No price data available
        </div>
      );
    }
    const maxPrice = Math.max(...validData.map((d) => d.price));
    const minPrice = Math.min(...validData.map((d) => d.price));
    const priceRange = maxPrice - minPrice;
    const normalizedData = validData.map((d, index) => ({
      x: (index / (validData.length - 1)) * 100,
      y: 100 - ((d.price - minPrice) / priceRange) * 100,
    }));
    const points = normalizedData.map((d) => `${d.x},${d.y}`).join(" ");
    return (
      <div className="w-full h-full relative">
        <svg
          viewBox="0 0 100 100"
          className="w-full h-full" >
          <polyline
            fill="none"
            stroke="#3b82f6"
            strokeWidth="2"
            points={points} />
        </svg>
        <div
          className="absolute bottom-0 left-0 w-full flex justify-between text-xs text-gray-400" >
          {validData
            .filter((_, i) => i % 4 === 0)
            .map((d, index) => (
              <span key={index}>
                {d.time}
              </span>
            ))}
        </div>
        <div
          className="absolute top-0 right-0 h-full flex flex-col justify-between text-xs text-gray-400" >
          <span>${maxPrice.toFixed(2)}</span>
          <span>${minPrice.toFixed(2)}</span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="max-w-full p-6 bg-gray-900 text-white min-h-screen relative" >
      <div
        className="flex justify-between items-center mb-6" >
        <div className="flex items-center">
          <button
            onClick={handleBundleToggle}
            className={`flex items-center ${isBundled ? "bg-blue-600" : "bg-gray-600"} hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mr-4`} >
            {isBundled ? (
              <PackageIcon className="mr-2" size={16} />
            ) : (
              <LayersIcon className="mr-2" size={16} />
            )}
            {isBundled ? "Bundled" : "Individual"}
          </button>
          {!isBundled && (
            <button
              onClick={() => setShowWalletPopup(true)}
              className="bg-gray-800 text-white rounded px-4 py-2 hover:bg-gray-700" >
              Select Wallet
            </button>
          )}
        </div>
        <button
          onClick={() => setShowNetworkPopup(true)}
          className="bg-gray-800 text-white rounded px-4 py-2 hover:bg-gray-700 flex items-center" >
          <FilterIcon className="mr-2" size={16} />
          Filter Networks
        </button>
      </div>
      <form onSubmit={handleSubmit} className="mb-8">
        <div
          className={`flex items-center border-b ${showError ? "border-red-500" : "border-gray-700"} py-2`} >
          <WalletIcon
            className={`${showError ? "text-red-500" : "text-gray-400"} mr-2`} />
          <input
            type="text"
            placeholder="Enter wallet address"
            value={walletAddress}
            onChange={handleWalletChange}
            className={`appearance-none bg-transparent border-none w-full ${showError ? "text-red-500" : "text-white"} mr-3 py-1 px-2 leading-tight focus:outline-none`}
            aria-label="Wallet address" />
          <button
            type="submit"
            className="flex-shrink-0 bg-blue-600 hover:bg-blue-800 border-blue-600 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded" >
            <SearchIcon size={16} />
          </button>
        </div>
        {showError && (
          <p className="text-red-500 text-sm mt-1">
            Invalid Address
          </p>
        )}
      </form>
      {isBundled && (
        <div
          className="mb-6 p-4 bg-gray-800 rounded-lg shadow-md" >
          <h3 className="text-lg font-semibold mb-2">
            Bundled Wallets
          </h3>
          <ul className="text-gray-400 text-sm">
            {wallets.map((wallet) => (
              <li key={wallet.id} className="flex justify-between items-center mb-2">
                <span>{wallet.address}</span>
                <button
                  onClick={() => removeWallet(wallet.id)}
                  className="text-red-500 hover:text-red-700 transition-colors duration-200"
                  title="Remove wallet"
                >
                  <XCircleIcon size={20} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div
        className="bg-gray-800 rounded-lg shadow-md p-6 mb-8" >
        <h2 className="text-xl font-semibold mb-4">
          Portfolio Overview
        </h2>
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-400">
              Total Value
            </p>
            <p className="text-3xl font-bold">
              ${totalValue.toLocaleString()}
            </p>
          </div>
          <div className="flex items-center">
            <CoinsIcon
              className="text-yellow-400 mr-2"
              size={24} />
            <span className="text-lg font-semibold">
              {filteredAssets.length} Assets
            </span>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mb-8">
        <table
          className="min-w-full bg-gray-800 rounded-lg shadow-md" >
          <thead>
            <tr>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Name
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Amount
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Value
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Change
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Network
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedAssets.map((asset) => (
              <React.Fragment
                key={asset.symbol + asset.network} >
                <tr
                  className="cursor-pointer hover:bg-gray-700"
                  onClick={() => handleAssetClick(asset.symbol)} >
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white" >
                    {asset.name} ({asset.symbol})
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                    {asset.amount.toLocaleString()} {asset.symbol}
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                    ${asset.value.toLocaleString()}
                  </td>
                  <td
                    className={`px-6 py-4 whitespace-nowrap flex items-center ${asset.change >= 0 ? "text-green-400" : "text-red-400"}`} >
                    {asset.change >= 0 ? (
                      <TrendingUpIcon
                        size={16}
                        className="mr-1" />
                    ) : (
                      <TrendingDownIcon
                        size={16}
                        className="mr-1" />
                    )}
                    <span className="font-semibold">
                      {Math.abs(asset.change)}%
                    </span>
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                    {asset.network}
                  </td>
                </tr>
                {expandedAsset === asset.symbol && (
                  <tr>
                    <td colSpan={5} className="px-6 py-4">
                      <div className="p-4 rounded-lg">
                        <h3
                          className="text-lg font-semibold mb-2" >
                          {asset.name} Chart (24h)
                        </h3>
                        <div className="h-64 rounded-lg">
                          <LineChart
                            data={asset.chartData} />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-center items-center space-x-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="text-gray-400 hover:text-white p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Previous page"
          >
            <ChevronLeftIcon size={24} />
          </button>
          <span className="text-gray-400">
            Showing {(currentPage - 1) * assetsPerPage + 1} - {Math.min(currentPage * assetsPerPage, sortedAssets.length)} of {sortedAssets.length} results
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage * assetsPerPage >= sortedAssets.length}
            className="text-gray-400 hover:text-white p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Next page"
          >
            <ChevronRightIcon size={24} />
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div
          className="flex justify-between items-center mb-4" >
          <h2 className="text-xl font-semibold">
            Latest Trades
          </h2>
          <button
            onClick={() => setShowExchangePopup(true)}
            className="bg-gray-800 text-white rounded px-4 py-2 hover:bg-gray-700 flex items-center" >
            <FilterIcon className="mr-2" size={16} />
            Filter Exchanges
          </button>
        </div>
        <table
          className="min-w-full bg-gray-800 rounded-lg shadow-md" >
          <thead>
            <tr>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Pair
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Type
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Amount (USD)
              </th>
              {/* <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Price
              </th> */}
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Time
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Network
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider" >
                Exchange
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedTrades.map((trade) => (
              <tr
                key={trade.id}
                className="hover:bg-gray-700" >
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white" >
                  {trade.pair}
                </td>
                <td
                  className={`px-6 py-4 whitespace-nowrap text-sm ${trade.side === Side.Buy ? "text-green-400" : "text-red-400"}`} >
                  <div className="flex items-center">
                    {trade.side === Side.Buy ? (
                      <ArrowUpIcon
                        size={16}
                        className="mr-1" />
                    ) : (
                      <ArrowDownIcon
                        size={16}
                        className="mr-1" />
                    )}
                    {trade.side}
                  </div>
                </td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                  ${trade.amount}
                </td>
                {/* <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                  {trade.price.toLocaleString()}
                </td> */}
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                  {trade.time}
                </td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                  {trade.network}
                </td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-400" >
                  {trade.exchange}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-center items-center space-x-4">
          <button
            onClick={handlePrevTradePage}
            disabled={currentTradePage === 1}
            className="text-gray-400 hover:text-white p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Previous page"
          >
            <ChevronLeftIcon size={24} />
          </button>
          <span className="text-gray-400">
            Showing {(currentTradePage - 1) * tradesPerPage + 1} - {Math.min(currentTradePage * tradesPerPage, filteredTrades.length)} of {filteredTrades.length} results
          </span>
          <button
            onClick={handleNextTradePage}
            disabled={currentTradePage * tradesPerPage >= filteredTrades.length}
            className="text-gray-400 hover:text-white p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Next page"
          >
            <ChevronRightIcon size={24} />
          </button>
        </div>
      </div>
      {showWalletPopup && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" >
          <div className="bg-gray-800 rounded-lg p-6 w-96">
            <div
              className="flex justify-between items-center mb-4" >
              <h3 className="text-xl font-semibold">
                Select Wallet
              </h3>
              <button
                onClick={() => setShowWalletPopup(false)}
                className="text-gray-400 hover:text-white" >
                <XIcon size={24} />
              </button>
            </div>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left pb-2">
                    Address
                  </th>
                  <th className="text-right pb-2">
                    Total Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {wallets.map((wallet) => (
                  <tr
                    key={wallet.id}
                    className="cursor-pointer hover:bg-gray-700"
                    onClick={() => handleWalletSelect(wallet.id)} >
                    <td className="py-2">
                      {shortenAddress(wallet.address)}
                    </td>
                    <td className="text-right py-2">
                      ${wallet.totalValue.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {showNetworkPopup && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" >
          <div
            className="bg-gray-800 rounded-lg p-6 w-96" >
            <div
              className="flex justify-between items-center mb-4" >
              <h3 className="text-xl font-semibold">
                Select Networks
              </h3>
              <button
                onClick={() => setShowNetworkPopup(false)}
                className="text-gray-400 hover:text-white" >
                <XIcon size={24} />
              </button>
            </div>
            <div className="space-y-2">
              {networkOptions.map((network) => (
                <div
                  key={network.value}
                  className="flex items-center cursor-pointer"
                  onClick={() => handleNetworkSelect(network.value)} >
                  {selectedNetworks.includes(network.value) ? (
                    <CheckSquareIcon
                      size={20}
                      className="mr-2 text-blue-500" />
                  ) : (
                    <SquareIcon
                      size={20}
                      className="mr-2 text-gray-400" />
                  )}
                  <span>{network.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showExchangePopup && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" >
          <div
            className="bg-gray-800 rounded-lg p-6 w-96" >
            <div
              className="flex justify-between items-center mb-4" >
              <h3 className="text-xl font-semibold">
                Select Exchanges
              </h3>
              <button
                onClick={() => setShowExchangePopup(false)}
                className="text-gray-400 hover:text-white" >
                <XIcon size={24} />
              </button>
            </div>
            <div className="space-y-2">
              {exchangeOptions.map((exchange) => (
                <div
                  key={exchange.value}
                  className="flex items-center cursor-pointer"
                  onClick={() => handleExchangeSelect(exchange.value)} >
                  {selectedExchanges.includes(exchange.value) ? (
                    <CheckSquareIcon
                      size={20}
                      className="mr-2 text-blue-500" />
                  ) : (
                    <SquareIcon
                      size={20}
                      className="mr-2 text-gray-400" />
                  )}
                  <span>{exchange.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 flex flex-col items-center">
            <h3 className="text-xl font-semibold mb-4">Retrieving assets</h3>
            <Loader2 className="animate-spin" size={48} />
          </div>
        </div>
      )}
    </div>
  );
};
export default CryptoDashboard;
render(
  <CryptoDashboard />,
  document.getElementById("root"),
);
