// Import the configuration from a separate file
import config from '../config';

import axios, { AxiosInstance } from 'axios';
import { Asset, Trade } from '../types';

class Backend {
  private axiosClient: typeof AxiosInstance;

  constructor() {
    this.axiosClient = axios.create({
      baseURL: config.apiBaseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async fetchAssets(address: string): Promise<Asset[]> {
    try {
      const response = await this.axiosClient.get(`/api/v1/getAssets?address=${address}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching assets:', error);
      return [];
    }
  }

  async fetchTrades(address: string): Promise<Trade[]> {
    try {
      const response = await this.axiosClient.get(`/api/v1/getTrades?address=${address}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching assets:', error);
      return [];
    }
  }
}

export default Backend;
